import { atom, selector, selectorFamily } from "recoil";
import axios from "axios";

// used to swap between .dev and .online for development.
var extension = window.location.origin.endsWith('.online') ? '.online' : '.dev'

const endpoint = 'https://cc.sagebrush' + extension + '/commconn_be'

export const usersAtom = atom({
  key: 'usersAtom',
  default: selector({
    key: 'usersSelector',
    get: async () => {
      return await getListOfUsers()
    }
  })
})

const sortByName = (a, b) => {
  if (a.name > b.name) return 1
  if (a.name < b.name) return -1
  return 0
}

export const getListOfUsers = async () => {
  return await axios({
    method: 'post',
    url: endpoint + '/getListOfUsers',
  }).then((msg) => {
    return msg.data.sort(sortByName)
  }).catch(error => {
    return []
  })
}

export const addUser = async (user) => {
  return await axios({
    method: 'post',
    url: endpoint + '/addUser',
    data: user
  }).then((res) => {
    return res.data
  })
}

export const editUser = async (user) => {
  return await axios({
    method: 'post',
    url: endpoint + '/editUser',
    data: user
  })
}

export const getUser = selectorFamily({
  key: 'getUser',
  get: (user) => async () => {
    return await axios({
      method: 'post',
      url: endpoint + '/getUserByID',
      data: user
    }).then((res) => {
      return res.data
    }).catch(error => {
      return null
    })
  }
})

export const formatPhoneNumber = (number) => {
  return `(${number.substring(0, 3)})${number.substring(3, 6)}-${number.substring(6, 11)}`
}