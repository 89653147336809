import { useEffect, useState } from 'react';

const DateTime = () => {

  function getFormattedDate(curDate) {
    var year = curDate.getFullYear();

    var month = (1 + curDate.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;

    var day = curDate.getDate().toString();
    day = day.length > 1 ? day : '0' + day;

    var ampm = 'AM'
    var hours = curDate.getHours()
    if (hours >= 12 && hours !== 24) {
      ampm = 'PM'
    }
    if (hours > 12) {
      hours -= 12
    }

    hours = hours.toString()

    var minutes = curDate.getMinutes().toString()

    minutes = minutes.length === 1 ? `0${minutes}` : minutes

    return `${month}/${day}/${year} ${hours}:${minutes} ${ampm}`;
  }

  const [dateTime, setDateTime] = useState(getFormattedDate(new Date()))

  const updateVal = () => {
    if (val === 0) {
      setVal(1)
    } else {
      setVal(0)
    }
  }

  const [val, setVal] = useState(0)

  useEffect(() => {
    const timer = setTimeout(updateVal, 1000 * 5) // update every 5 seconds
    setDateTime(getFormattedDate(new Date()))
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [val]);
  return (
    <div style={{ textAlign: 'center', marginBottom: '20px', color: '#4d4c4c' }}>
      <h2>{dateTime}</h2>
    </div>
  )
}

export default DateTime