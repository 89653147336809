import React from 'react'
import { useParams } from "react-router-dom";
import { getUser, formatPhoneNumber } from '../../state/user';
import { getUsersServices } from '../../state/service';
import { useRecoilValue } from 'recoil';
import Loading from '../../components/Loading';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons'

import './user.css'

library.add(fas)

const UserComp = () => {
  const key = window.location.search?.split('=')[1] || ''
  const id = useParams().id
  const user = useRecoilValue(getUser({ id: id }))
  const services = useRecoilValue(getUsersServices({ id: id }))
  return (
    <div style={{ overflow: 'auto', padding: '5px' }}>
      <button
        onClick={(e) => {
          window.location.href = `/?key=${key}`
        }}
        className='userBackButton'
      >
        <FontAwesomeIcon icon="fa-solid fa-chevron-right" style={{ transform: 'rotate(180deg)', marginRight: '7px' }} />
        Back
      </button>
      <div className='userInfoBox'>
        {user ?
          <>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h1>{user.name}</h1>
              <button
                className='userEditButton'
                onClick={(e) => {
                  window.location.href = `/UserProfile/Form/${id}?key=${key}`
                }}
              >
                <FontAwesomeIcon icon="fa-solid fa-pen-to-square" />
              </button>
            </div>
            <div style={{ fontSize: '20px' }}>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <p style={{ color: '#d18d35', marginRight: '5px' }}><strong>Phone:</strong></p>
                <p style={{ lineBreak: 'anywhere' }}>{user.phone ? formatPhoneNumber(user.phone) : 'N/A'}</p>
              </div>

              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <p style={{ color: '#d18d35', marginRight: '5px' }}><strong>Email:</strong></p>
                <p style={{ lineBreak: 'anywhere' }}>{user.email ? user.email : 'N/A'}</p>
              </div>
            </div>
            <p style={{ fontSize: '20px', color: '#d18d35' }}><strong>Notes:</strong></p>
            <p style={{ padding: '5px 10px', fontSize: '18px' }}>{user.notes ? user.notes : 'N/A'}</p>
          </>
          : <p style={{ display: 'flex', justifyContent: 'center' }}>User Not Found.</p>}
      </div>
      {user && (
        <>

          <div style={{ display: 'flex', justifyContent: 'space-between', margin: '15px 0px' }}>
            <h3 style={{ color: '#ed9f3c', fontSize: '30px' }}><strong>Services:</strong></h3>
            <button
              className='userBackButton'
              style={{ width: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              onClick={(e) => {
                window.location.href = `/AddService/${id}?key=${key}`
              }}
            >
              <FontAwesomeIcon icon="fa-solid fa-plus" />
            </button>
          </div>
          {services.length > 0 ?
            <div>
              {services.map(item => {
                return (
                  <div className='userInfoBox' key={item.id}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <h3 style={{ color: '#ed9f3c' }}><strong>{item.service}</strong></h3>
                      <p>{item.date.split(' ')[0]}</p>
                    </div>
                    <p>{item.notes}</p>
                  </div>
                )
              })}
            </div>
            :
            <p style={{ display: 'flex', justifyContent: 'center' }}>No Service History.</p>}

        </>
      )}
    </div>
  )
}

const User = () => {
  return (
    <React.Suspense fallback={<Loading />}>
      <UserComp />
    </React.Suspense>
  )
}


export default User