import { selectorFamily } from "recoil";
import axios from "axios";

// used to swap between .dev and .online for development.
var extension = window.location.origin.endsWith('.online') ? '.online' : '.dev'

const endpoint = 'https://cc.sagebrush' + extension + '/commconn_be'


export const addService = async (service) => {
  return await axios({
    method: 'post',
    url: endpoint + '/addService',
    data: service
  })
}

// export const filterServiceByDate = (a, b) => {
//   if (a.date < b.date) return 1
//   if (a.date > b.date) return -1
//   return 0
// }

export const getUsersServices = selectorFamily({
  key: 'getUsersServices',
  get: (user) => async () => {
    return await axios({
      method: 'post',
      url: endpoint + '/requestUsersPastServices',
      data: user
    }).then((res) => {
      return res.data
    }).catch(error => {
      return []
    })
  }
})