// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pageBackground{
  width: 100vw;
  height: 100vh;
  background-color: #eeeeee;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.fullPage{
  display: flex;
  width: 100%;
  max-height: calc(100vh - 60px);
  flex-direction: column;
  padding: 30px;
  overflow: hidden;
  max-width: 1000px;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ed9f3c;
  border-radius: 5px;
}

::-webkit-scrollbar-corner{
  background: transparent;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,yBAAyB;EACzB,gBAAgB;EAChB,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,WAAW;EACX,8BAA8B;EAC9B,sBAAsB;EACtB,aAAa;EACb,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA,UAAU;AACV;EACE,UAAU;EACV,WAAW;;AAEb;;AAEA,UAAU;AACV;EACE,uBAAuB;AACzB;;AAEA,WAAW;AACX;EACE,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,uBAAuB;AACzB","sourcesContent":[".pageBackground{\n  width: 100vw;\n  height: 100vh;\n  background-color: #eeeeee;\n  overflow: hidden;\n  display: flex;\n  justify-content: center;\n}\n\n.fullPage{\n  display: flex;\n  width: 100%;\n  max-height: calc(100vh - 60px);\n  flex-direction: column;\n  padding: 30px;\n  overflow: hidden;\n  max-width: 1000px;\n}\n\n/* width */\n::-webkit-scrollbar {\n  width: 5px;\n  height: 5px;\n  \n}\n\n/* Track */\n::-webkit-scrollbar-track {\n  background: transparent;\n}\n\n/* Handle */\n::-webkit-scrollbar-thumb {\n  background: #ed9f3c;\n  border-radius: 5px;\n}\n\n::-webkit-scrollbar-corner{\n  background: transparent;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
