import React, { useState } from 'react'
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons'

import { usersAtom, formatPhoneNumber } from '../../state/user';


import './trackerSearch.css'
import Loading from '../../components/Loading';
import { useRecoilValue } from 'recoil';

library.add(fas)

const TrackerSearchComp = () => {
  const [search, setSearch] = useState('')
  const users = useRecoilValue(usersAtom)
  const key = window.location.search?.split('=')[1] || ''

  const filterBySearch = (list) => {
    if (search.length === 0) return []
    return list.filter(item => item.name.toLowerCase().includes(search) ||
      item.phone.includes((search.replaceAll('-', '').replaceAll('(', '').replaceAll(')', ''))) ||
      item.email.toLowerCase().includes(search))
  }

  return (
    <div className='trackerPageGrid'>
      <div className='trackerPageBody'>
        <div style={{ display: 'flex', marginBottom: '10px' }}>
          <input
            className='trackerSearchBox'
            placeholder='Search...'
            type='text'
            onChange={(e) => setSearch(e.target.value.toLowerCase())}
            value={search}
          />
          <button
            className='trackerBackspaceButton'
            onClick={(e) => {
              setSearch(prev => {
                if (prev.length <= 1) {
                  return ''
                }
                return prev.substring(0, prev.length - 1)
              })
            }}
          >
            <FontAwesomeIcon icon="fa-solid fa-delete-left" style={{ transform: 'scale(2.5)' }} />
          </button>
        </div>
        <div
          style={{
            overflowY: 'auto',
            padding: '0px 5px 5px 5px'
          }}>
          {filterBySearch(users).map((user, index) => {
            return (
              <div
                className='trackerSelectionOption'
                style={{
                  marginTop: index === 0 ? '0px' : '10px'
                }}
                key={user.id}
                onClick={(e) => {
                  window.location.href = `/UserProfile/${user.id}?key=${key}`
                }}
              >
                <h2>{user.name}</h2>
                <div
                  style={{ fontSize: '18px', lineBreak: 'anywhere' }}
                >
                  <p>
                    <strong style={{ color: '#ed9f3c' }}>Phone: </strong>{user.phone ? formatPhoneNumber(user.phone) : 'N/A'}
                  </p>
                  <p>
                    <strong style={{ color: '#ed9f3c' }}>Email: </strong>{user.email ? user.email : 'N/A'}
                  </p>
                </div>
              </div>
            )
          })}
          {search.length !== 0 && (<button className='addNewUserSelectionOption'
            onClick={(e) => {
              window.location.href = `/UserProfile/Form/NewUser?key=${key}`
            }}
          >
            <h1>+ Add New User</h1>
          </button>)}
        </div>
      </div>
    </div>
  )
}

const TrackerSearch = () => {
  return (
    <React.Suspense fallback={<Loading />}>
      <TrackerSearchComp />
    </React.Suspense>
  )
}



export default TrackerSearch