// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.userBackButton{
  border-radius: 10px;
  border: none;
  outline: none;
  background-color: #ed9f3c;
  color: white;
  padding: 10px 20px;
  font-size: 20px;
  cursor: pointer;
  width: 120px;
}

.userBackButton:hover{
  background-color: #d18d35;
}

.userInfoBox{
  margin-top: 10px;
  background-color: #ffffff;
  border-radius: 15px;
  border: 1px solid #aaaaaa;
  box-shadow: -1px 2px 4px 0px #bbbbbb;
  padding: 5px 15px;
  color: black;
  overflow-y: scroll;
}

.userEditButton{
  border: none;
  outline: none;
  color: #ed9f3c;
  background-color: transparent;
  cursor: pointer;
  transform: scale(1.8);
  margin-right: 5px;
}

.userEditButton:hover{
  color: #d18d35
}`, "",{"version":3,"sources":["webpack://./src/Views/user/user.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,YAAY;EACZ,aAAa;EACb,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,eAAe;EACf,YAAY;AACd;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,gBAAgB;EAChB,yBAAyB;EACzB,mBAAmB;EACnB,yBAAyB;EACzB,oCAAoC;EACpC,iBAAiB;EACjB,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,cAAc;EACd,6BAA6B;EAC7B,eAAe;EACf,qBAAqB;EACrB,iBAAiB;AACnB;;AAEA;EACE;AACF","sourcesContent":[".userBackButton{\n  border-radius: 10px;\n  border: none;\n  outline: none;\n  background-color: #ed9f3c;\n  color: white;\n  padding: 10px 20px;\n  font-size: 20px;\n  cursor: pointer;\n  width: 120px;\n}\n\n.userBackButton:hover{\n  background-color: #d18d35;\n}\n\n.userInfoBox{\n  margin-top: 10px;\n  background-color: #ffffff;\n  border-radius: 15px;\n  border: 1px solid #aaaaaa;\n  box-shadow: -1px 2px 4px 0px #bbbbbb;\n  padding: 5px 15px;\n  color: black;\n  overflow-y: scroll;\n}\n\n.userEditButton{\n  border: none;\n  outline: none;\n  color: #ed9f3c;\n  background-color: transparent;\n  cursor: pointer;\n  transform: scale(1.8);\n  margin-right: 5px;\n}\n\n.userEditButton:hover{\n  color: #d18d35\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
