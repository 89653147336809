import React from 'react'
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons'
import { useParams } from "react-router-dom";

import { getUser, addUser, editUser, usersAtom, getListOfUsers, formatPhoneNumber } from '../../state/user';

import { Formik, Form, Field, ErrorMessage } from 'formik';

import Loading from '../../components/Loading';
import { useRecoilState, useRecoilValue } from 'recoil';

import './userForm.css'
import '../user/user.css'
import '../trackerSearch/trackerSearch.css'
import LoadingBars from '../../components/LoadingBars';

library.add(fas)

const UserFormComp = () => {
  const key = window.location.search?.split('=')[1] || ''
  const id = useParams().id
  const user = useRecoilValue(getUser({ id: id }))

  const [users, setUsers] = useRecoilState(usersAtom)

  const newUser = {
    name: '',
    phone: '',
    email: '',
    notes: ''
  }

  const submitForm = async (values) => {
    let userID = id
    if (id === 'NewUser') {
      userID = await addUser(values)
    } else {
      await editUser(values)
    }
    setUsers(await getListOfUsers())

    window.location.href = `/UserProfile/${userID}?key=${key}`
  }

  const initalValues = user ?
    {
      ...user,
    }
    :
    newUser
  return (
    <div style={{ overflow: 'auto', padding: '5px' }}>
      <button
        onClick={(e) => {
          if (user) {
            window.location.href = `/UserProfile/${id}?key=${key}`
          } else {
            window.location.href = `/?key=${key}`
          }
        }}
        className='userBackButton'
      >
        <FontAwesomeIcon icon="fa-solid fa-chevron-right" style={{ transform: 'rotate(180deg)', marginRight: '7px' }} />
        Back
      </button>

      {user ?
        <UserFormikForm
          initialValues={initalValues}
          submit={submitForm}
        />
        :
        id === 'NewUser' ?
          <UserFormikForm
            initialValues={initalValues}
            submit={submitForm}
            users={users}
            pageKey={key}
          />
          :
          <div className='userInfoBox' style={{ paddingTop: '20px' }}>
            <p style={{ textAlign: 'center' }}>User Doesn't Exist.</p>
          </div>
      }

    </div>
  )
}
const UserForm = () => {
  return (
    <React.Suspense fallback={<Loading />}>
      <UserFormComp />
    </React.Suspense>
  )
}

const UserFormikForm = ({ initialValues, submit, users, pageKey }) => {

  const validateForm = (values) => {
    let errors = {}
    if (!values.name) {
      errors.name = 'Required'
    }

    if (!(values.name.trim()).includes(' ')) {
      errors.name = 'Please Include First and Last Name'
    }

    if (values.email && !/[A-Za-z0-9_!#$%&/'*+=?`{|}~^.-]+@[A-Za-z0-9.-]+/.test(values.email)) {
      errors.email = 'Email Formatted Incorrectly.'
    }

    if (values.phone && (values.phone.toString()).length !== 10) {
      errors.phone = 'Phone number must be 10 digits.'
    }
    return errors
  }

  const filterBySearch = (values, users) => {
    return users.filter(item => (item.name.toLowerCase() === values.name.toLowerCase() && values.name !== '') ||
      (item.phone === values.phone.toString() && values.phone !== '') ||
      (item.email.toLowerCase() === values.email.toLowerCase() && values.email !== ''))
  }
  return (
    <React.Fragment>
      <Formik
        initialValues={initialValues}
        onSubmit={submit}
        validate={validateForm}
      >
        {({ isSubmitting, values }) => (
          <Form>
            {users && (
              <>
                {filterBySearch(values, users).length > 0 && (
                  <div className='userInfoBox'>
                    <p>User may already exist. See existing user(s) below.</p>
                  </div>
                )}
              </>
            )}
            <div className='userInfoBox'>
              <div className='userFormInputFieldGroups'>
                <label style={{ paddingRight: '5px' }}>Name:</label>
                <div>
                  <Field
                    className='userFormInputField'
                    type='text'
                    name='name'
                  />
                  <ErrorMessage name="name" render={msg => <ErrorMsgPopup msg={msg} />} />
                </div>
              </div>

              <div className='userFormInputFieldGroups'>
                <label style={{ paddingRight: '5px' }}>Phone:</label>
                <div>
                  <Field
                    className='userFormInputField'
                    type='number'
                    name='phone'
                  />
                  <ErrorMessage name="phone" render={msg => <ErrorMsgPopup msg={msg} />} />
                </div>
              </div>

              <div className='userFormInputFieldGroups'>
                <label style={{ paddingRight: '5px' }}>Email:</label>
                <div style={{ width: '500px', minWidth: '250px' }}>
                  <Field
                    style={{ width: 'calc(100% - 15px)' }}
                    className='userFormInputField'
                    type='text'
                    name='email'
                  />
                  <ErrorMessage name="email" render={msg => <ErrorMsgPopup msg={msg} />} />
                </div>
              </div>

              <div className='userFormInputFieldGroups' style={{ flexDirection: 'column' }}>
                <label style={{ paddingBottom: '5px' }}>Notes:</label>
                <Field
                  className='userFormInputField'
                  component='textarea'
                  rows='8'
                  name='notes'
                />
              </div>
              <div style={{ display: 'flex', justifyContent: 'right', marginBottom: '10px' }}>
                <button
                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                  type='submit'
                  className='userBackButton'
                >
                  {isSubmitting ?
                    <LoadingBars width='40px' />
                    :
                    'Submit'
                  }

                </button>
              </div>
            </div>

            {users && (
              <div>
                {filterBySearch(values, users).length > 0 && (
                  <div>
                    <h2 style={{ color: '#ed9f3c', fontSize: '30px', marginTop: '10px' }}>Existing User(s):</h2>
                    {filterBySearch(values, users).map((user, index) => {
                      return (
                        <div
                          className='trackerSelectionOption'
                          style={{
                            marginTop: '10px'
                          }}
                          key={user.id}
                          onClick={(e) => {
                            window.location.href = `/UserProfile/${user.id}?key=${pageKey}`
                          }}
                        >
                          <h2>{user.name}</h2>
                          <div
                            style={{ fontSize: '18px', lineBreak: 'anywhere' }}
                          >
                            <p>
                              <strong style={{ color: '#ed9f3c' }}>Phone: </strong>{user.phone ? formatPhoneNumber(user.phone) : 'N/A'}
                            </p>
                            <p>
                              <strong style={{ color: '#ed9f3c' }}>Email: </strong>{user.email ? user.email : 'N/A'}
                            </p>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                )
                }
              </div>
            )}

          </Form>
        )}
      </Formik>
    </React.Fragment>
  )
}

const ErrorMsgPopup = ({ msg }) => {
  return (
    <div style={{ display: 'flex', color: 'black', fontSize: '16px', fontWeight: 'normal' }}>
      <p>*</p>
      <p>{msg}</p>
    </div>
  )
}

export default UserForm