import './App.css';
import logo from './Assets/M1 Community Connect Logo.png'
import TrackerSearch from './Views/trackerSearch/TrackerSearch';

import { BrowserRouter, Route, Routes } from "react-router-dom";
import AxiosInterceptor from './components/AxiosInterceptor';
import DateTime from './components/DateTime';
import User from './Views/user/User';
import UserForm from './Views/userForm/UserForm';
import ServicesForm from './Views/servicesForm/ServicesForm';

function App() {

  return (
    <div className='pageBackground'>
      <AxiosInterceptor />
      <div className='fullPage'>
        <div
          style={{
            textAlign: 'center',
            maxWidth: '100%',
            maxHeight: '8vh',
            marginBottom: '10px',
          }}>
          <img
            style={{ maxWidth: '90vw', maxHeight: '8vh', objectFit: 'contain' }}
            src={logo}
            title='M1: Community Connect'
            alt='M1: Community Connect'
          />
        </div>
        <DateTime />
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<TrackerSearch />} />
            <Route path='*' element={<TrackerSearch />} />
            <Route exact path='/UserProfile/:id' Component={User} />
            <Route exact path='/UserProfile/Form/:id' Component={UserForm} />
            <Route exact path='/AddService/:id' Component={ServicesForm} />
          </Routes>
        </BrowserRouter>

      </div>
    </div>
  );
}

export default App;
