// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.userFormInputFieldGroups{
  display: flex;
  flex-wrap: wrap;
  font-size: 28px;
  font-weight: bold;
  color: #ed9f3c;
  margin-bottom: 15px;
}

.userFormInputField{
  border-radius: 5px;
  outline: none;
  border: 1px solid #afafaf;
  padding: 5px 10px;
  min-width: 250px;
  font-size: 20px;
}

.userFormInputField:active{
  border: 1px solid black
}

.userFormInputField:hover{
  border: 1px solid black
}`, "",{"version":3,"sources":["webpack://./src/Views/userForm/userForm.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,eAAe;EACf,iBAAiB;EACjB,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,yBAAyB;EACzB,iBAAiB;EACjB,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE;AACF;;AAEA;EACE;AACF","sourcesContent":[".userFormInputFieldGroups{\n  display: flex;\n  flex-wrap: wrap;\n  font-size: 28px;\n  font-weight: bold;\n  color: #ed9f3c;\n  margin-bottom: 15px;\n}\n\n.userFormInputField{\n  border-radius: 5px;\n  outline: none;\n  border: 1px solid #afafaf;\n  padding: 5px 10px;\n  min-width: 250px;\n  font-size: 20px;\n}\n\n.userFormInputField:active{\n  border: 1px solid black\n}\n\n.userFormInputField:hover{\n  border: 1px solid black\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
