import React from 'react'
import axios from "axios";

axios.interceptors.request.use(
  (config) => {
    let params = window.location.search?.split('=') || ''
    let key = window.location.search?.split('=')[1] || ''
    if (key && params[0] === '?key') {
      config.headers["cc-access-token"] = key;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

const AxiosInterceptor = () => {
  axios.interceptors.response.use(
    async (response) => {
      return response
    },
    function (error) {
      if (error.response.status !== 304) {
        window.location.href = 'https://www.sagebrush.church/'
      }

      return Promise.reject(error);
    }
  )

  return (
    <React.Fragment></React.Fragment>
  )
}

export default AxiosInterceptor