import React from 'react'
import loading from '../Assets/M1_Loading.gif'

const Loading = () => {
  return (
    <React.Fragment>
      <p style={{ textAlign: 'center' }}>
        <img src={loading} />
      </p>
    </React.Fragment>
  )
}

export default Loading