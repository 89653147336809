import React from 'react'
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons'
import { useParams } from "react-router-dom";

import { getUser } from '../../state/user';

import { addService } from '../../state/service';

import { Formik, Form, Field, ErrorMessage } from 'formik';

import Loading from '../../components/Loading';
import { useRecoilValue } from 'recoil';

import './servicesForm.css'
import '../user/user.css'
import LoadingBars from '../../components/LoadingBars';

library.add(fas)

const ServicesFormComp = () => {

  const key = window.location.search?.split('=')[1] || ''
  const id = useParams().id
  const user = useRecoilValue(getUser({ id: id }))

  const newService = {
    serviceName: '',
    notes: '',
    user: id
  }

  const validateForm = (values) => {
    let errors = {}
    if (values.serviceName.trim().length === 0) {
      errors.serviceName = 'Required'
    }

    if (values.notes.length > 300) {
      errors.notes = 'Max 300 Characters.'
    }

    return errors
  }
  const submitForm = async (values) => {
    let service = {
      service: values.serviceName,
      notes: values.notes,
      user: values.user
    }
    await addService(service)
    window.location.href = `/UserProfile/${id}?key=${key}`
    return
  }

  return (
    <React.Fragment>
      <div style={{ overflow: 'auto', padding: '5px' }}>
        <button
          onClick={(e) => {
            if (user) {
              window.location.href = `/UserProfile/${id}?key=${key}`
            } else {
              window.location.href = `/?key=${key}`
            }
          }}
          className='userBackButton'
        >
          <FontAwesomeIcon icon="fa-solid fa-chevron-right" style={{ transform: 'rotate(180deg)', marginRight: '7px' }} />
          Back
        </button>
        <div className='userInfoBox'>
          {user ?
            <Formik
              initialValues={newService}
              onSubmit={async (values) => await submitForm(values)}
              validate={validateForm}
            >
              {({ isSubmitting, values }) => (
                <Form>
                  <div className='userFormInputFieldGroups'>
                    <p style={{ paddingRight: '5px' }}>User: </p>
                    <p style={{ color: 'black' }}>{user.name}</p>
                  </div>
                  <div className='userFormInputFieldGroups'>
                    <label style={{ paddingRight: '5px' }}>Service:</label>
                    <div>
                      <Field
                        className='userFormInputField'
                        type='text'
                        name='serviceName'
                      />
                      <ErrorMessage name="serviceName" render={msg => <ErrorMsgPopup msg={msg} />} />
                    </div>
                  </div>

                  <div className='userFormInputFieldGroups' style={{ flexDirection: 'column' }}>
                    <label style={{ paddingBottom: '5px' }}>Notes:</label>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <Field
                        className='userFormInputField'
                        component='textarea'
                        rows='4'
                        name='notes'
                      />
                      <ErrorMessage name="notes" render={msg => <ErrorMsgPopup msg={msg} />} />
                    </div>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'right', marginBottom: '10px' }}>
                    <button
                      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                      type='submit'
                      className='userBackButton'
                    >
                      {isSubmitting ?
                        <LoadingBars width='40px' />
                        :
                        'Submit'
                      }

                    </button>
                  </div>
                </Form>
              )}
            </Formik>
            :
            <p style={{ textAlign: 'center' }}>
              Invalid User.
            </p>
          }
        </div>
      </div>
    </React.Fragment>
  )
}

const ServicesForm = () => {
  return (
    <React.Suspense fallback={<Loading />}>
      <ServicesFormComp />
    </React.Suspense>
  )
}

const ErrorMsgPopup = ({ msg }) => {
  return (
    <div style={{ display: 'flex', color: 'black', fontSize: '16px', fontWeight: 'normal' }}>
      <p>*</p>
      <p>{msg}</p>
    </div>
  )
}

export default ServicesForm